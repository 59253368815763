export function checkUsername($username) {
  return this.kalibri.post(`/api/v1/user/username/check`, {
    body: {
      fieldValue: $username,
    },
  })
}

export function getAllUsers($regex = '', $offset, $limit) {
  return this.kalibri.post('/api/v1/user/get', {
    body: {
      regex: $regex,
      offset: $offset,
      limit: $limit
    },
  })
}

export function getActiveUsers($regex = '', $offset, $limit) {
  return this.kalibri.post('/api/v1/user/get', {
    body: {
      regex: $regex,
      offset: $offset,
      limit: $limit,
      status: 'ACTIVE'
    },
  })
}

export function editUser(
  $userId,
  $username,
  $email,
  $phoneNumber,
  $name,
  $avatarUrl,
  $description,
  $password,
) {
  return this.kalibri.post('/api/v1/user/edit', {
    body: {
      userId: $userId,
      username: $username,
      email: $email,
      phoneNumber: $phoneNumber,
      name: $name,
      avatarUrl: $avatarUrl,
      description: $description,
      password: $password,
    },
  })
}

export function addUser(
  $password,
  $username,
  $email,
  $phoneNumber,
  $name,
  $avatarUrl,
  $description,
) {
  return this.kalibri.post('/api/v1/user', {
    body: {
      password: $password,
      username: $username,
      email: $email,
      phoneNumber: $phoneNumber,
      name: $name,
      avatarUrl: $avatarUrl,
      description: $description,
    },
  })
}

export function blockUsers(userIds) {
  return this.kalibri.post('/api/v1/user/block', {
    body: {
      userIds: userIds,
    },
  })
}

export function unblockUsers(userIds) {
  return this.kalibri.post('/api/v1/user/unblock', {
    body: {
      userIds: userIds,
    },
  })
}

export function eraseUsers(userIds) {
  return this.kalibri.post('/api/v1/user/erase', {
    body: {
      userIds: userIds,
    },
  })
}

export function deleteUsers(userIds) {
  return this.kalibri.post('/api/v1/user/delete', {
    body: {
      userIds: userIds,
    },
  })
}

export function activateUsers(userIds) {
  return this.kalibri.post('/api/v1/user/activate', {
    body: {
      userIds: userIds,
    },
  })
}
