export function getDevices($offset, $limit) {
  return this.kalibri.post('/api/v1/device/get', {
    body: {
      offset: $offset,
      limit: $limit,
    },
  })
}

export function updateDevice($pubTr, $pubTrOld) {
  return this.kalibri.post('/api/v1/device/update', {
    body: {
      pubTr: $pubTr,
      pubTrOld: $pubTrOld,
    },
  })
}

export function bindUser($userId, $pubTr) {
  return this.kalibri.post('/api/v1/device/binding/bind', {
    body: {
      userId: $userId,
      pubTr: $pubTr,
    },
  })
}

export function unbindUser($pubTr) {
  return this.kalibri.post('/api/v1/device/binding/unbind', {
    body: {
      pubTr: $pubTr,
    },
  })
}

export function blockDevices($pubTrs) {
  return this.kalibri.post('/api/v1/device/block', {
    body: {
      pubTrs: $pubTrs,
    },
  })
}

export function unblockDevices($pubTrs) {
  return this.kalibri.post('/api/v1/device/unblock', {
    body: {
      pubTrs: $pubTrs,
    },
  })
}

export function getBindingCandidate($offset, $limit) {
  return this.kalibri.post('/api/v1/device/binding/candidate/get', {
    body: {
      offset: $offset,
      limit: $limit,
    },
  })
}

export function addDevice($pubTr) {
  return this.kalibri.post('/api/v1/device/add', {
    body: {
      pubTr: $pubTr,
    },
  })
}
