import { appConstants, themeConstants } from '@constants'
import api from '@api'
import { usersActions, devicesActions, callActions, appActions } from '@actions'

export const startApp = () => {
  return async (dispatch) => {
    await dispatch(usersActions.getAllUsers('', 0, 100))
    await dispatch(devicesActions.getDevices(0, 100))
    await dispatch(callActions.getCall(0, 100))
  }
}

export const switchTheme = () => {
  return (dispatch, getState) => {
    const $state = getState()

    let $currenTheme = $state.app.theme

    let $newTheme =
      $currenTheme === themeConstants.DEFAULT
        ? themeConstants.DARK
        : themeConstants.DEFAULT

    dispatch({
      type: appConstants.SWITCH_THEME,
      payload: $newTheme,
    })
  }
}

export const toggleMenu = (...args) => {
  let isArg = !!args.length
  return (dispatch, getState) => {
    const $state = getState()

    dispatch({
      type: appConstants.TOGGLE_MENU,
      payload: isArg ? args[0] : !$state.app.isMenuClose,
    })
  }
}

export const login = ($username, $password) => {
  return async (dispatch) => {
    try {
      const { data } = await api.login($username, $password)
      api.setToken(data.token)

      dispatch({ type: appConstants.LOGIN, payload: appConstants.AUTH })
    } catch (err) {
      dispatch(appActions.showError())
    }
  }
}

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: appConstants.LOGOUT, payload: appConstants.NO_AUTH })
  }
}

export const getProfile = () => {
  return async (dispatch) => {
    try {
      await api.getProfile()
    } catch (err) {
      alert(`get profile error`)
    }
  }
}

export const toggleLoading = () => {
  return (dispatch, getState) => {
    const $state = getState()
    const $loading = $state.app.loading

    dispatch({
      type: appConstants.TOGGLE_LOADING,
      payload: !$loading,
    })
  }
}

export const toggleError = (error) => {
  console.log(error)
  return (dispatch, getState) => {
    const $state = getState()
    const $error = $state.app.error

    dispatch({
      type: appConstants.TOGGLE_ERROR,
      payload: !$error,
    })
  }
}

export const showError = () => {
  return (dispatch) => {
    dispatch({
      type: appConstants.TOGGLE_ERROR,
      payload: true,
    })
  }
}

export const hideError = () => {
  return (dispatch) => {
    dispatch({
      type: appConstants.TOGGLE_ERROR,
      payload: false,
    })
  }
}

export const setModal = (modalData) => {
  return (dispatch) => {
    dispatch({ type: appConstants.SET_MODAL, payload: modalData })
  }
}
