import { usersConstants } from '@constants'

const initialState = {
  users: [],
  usersSearchResult: [],
  activeUser: {},
  userListDirty: false,
}

export default function user(state = initialState, { type, payload = null }) {
  switch (type) {
    case usersConstants.SET_USERS:
      return {
        ...state,
        users: payload,
      }
    case usersConstants.SET_USERS_SEARCH_RESULT:
      return {
        ...state,
        usersSearchResult: payload,
        userListDirty: false
      }
    case usersConstants.MARK_USER_LIST_DIRTY:
      return {
        ...state,
        userListDirty: true,
      }
    case usersConstants.CLEAR_USERS_SEARCH_RESULT:
      return {
        ...state,
        usersSearchResult: [],
      }
    case usersConstants.BLOCK_USER:
      return {
        ...state,
        users: payload.newUsers,
        activeUser: payload.user,
      }
    case usersConstants.UNBLOCK_USER:
      return {
        ...state,
        users: payload.newUsers,
        activeUser: payload.user,
      }
    case usersConstants.SET_ACTIVE_USER:
      return {
        ...state,
        activeUser: payload,
      }
    case usersConstants.DELETE_USER:
      return {
        ...state,
        users: payload,
        activeUser: {},
      }
    case usersConstants.ERASE_USER:
      return {
        ...state,
        users: payload,
        activeUser: {},
      }
    default:
      return state
  }
}
