import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import App from './app/App'
import { Provider } from 'react-redux'
import { store } from '@helpers'
import { Preloader } from '@components'
import axios from 'axios'

axios.defaults.baseURL = window._env_.API_URL;
axios.defaults.headers.common['Authorization'] = window.sessionStorage.getItem("AUTH_TOKEN");

const Store = store
const root = document.getElementById('root')

ReactDOM.render(
  <Provider store={Store}>
    <Suspense fallback={<Preloader />}>
      <App />
    </Suspense>
  </Provider>,
  root,
)
