import axios from 'axios'

export function login($username, $password) {
  return this.kalibri.post('/api/v1/user/login', {
    body: {
      password: $password,
      username: $username,
    },
  })
}

export function setToken($token) {
  window.sessionStorage.setItem("AUTH_TOKEN", $token);
  axios.defaults.headers.common['Authorization'] = $token;
  return this.kalibri.addSessionHeaders({
    Authorization: $token,
  })
}

export function getProfile() {
  return this.kalibri.get('/api/v1/user/profile')
}
