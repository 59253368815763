const userConstants = {
  BLOCK_USER: 'BLOCK USER',
  UNBLOCK_USER: 'UNBLOCK USER',
  SET_USERS: 'SET USERS',
  MARK_USER_LIST_DIRTY: 'MARK USER LIST DIRTY',
  SET_USERS_SEARCH_RESULT: 'SET USERS SEARCH RESULT',
  CLEAR_USERS_SEARCH_RESULT: 'CLEAR USERS SEARCH RESULT',
  SET_ACTIVE_USER: 'SET ACTIVE USER',
  ADD_USER: 'ADD USER',
  ERASE_USER: 'ERASE USER',
  DELETE_USER: 'DELETE USER',
}

export default userConstants
