import React from 'react'
import HeaderKit, { Level, Hamburger, Logo } from '@sc-reactkit/header'
import { Spacer } from '@sc-reactkit/helpers'
import Button from '@sc-reactkit/button'
import styled from 'styled-components'
import Switch from '@sc-reactkit/switch'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { appActions } from '@actions'
import { useTranslation } from "react-i18next";

import { themeConstants } from '@constants'
import {TButton} from "@sc-reactkit/toolbar";

const Header = ({ theme, switchTheme, toggleMenu, logout, setModal }) => {

  const { t } = useTranslation();

  const logoutModal = {
    visible: true,
    type: 'error',
    heading: t('existConfirm'),
    successButton: t('existButton'),
    cancelButton: t('cancelButton'),
    success: () => {
      setModal({ visible: false })
      logout()
    },
    cancel: () => setModal({ visible: false }),
  }

  return (
    <HeaderKit>
      <Level>
        <Hamburger onClick={() => toggleMenu()} />
        <Logo>
          <SLogoImage />
        </Logo>
        <Spacer />
        <SHPadding>
          <Switch
            checked={theme === themeConstants.DEFAULT}
            disabled={false}
            name="theme-switch"
            onChange={() => switchTheme()}
          />
        </SHPadding>
        <TButton
          kind={'icon'}
          icon={'direction_in'}
          color={'charade'}
          // onClick={() => logout()}
          onClick={() => setModal(logoutModal)}
        />
      </Level>
    </HeaderKit>
  )
}

const mapStateToProps = ({ app }) => ({
  ...app,
})

const mapDispatchtoProps = (dispatch) => ({
  ...bindActionCreators(appActions, dispatch),
})

export default connect(mapStateToProps, mapDispatchtoProps)(Header)

const SLogoImage = styled.div`
  width: 130px;
  height: 35px;
  background-image: ${({ theme }) => theme.backgroundImage};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
`

const SHPadding = styled.div`
  padding: 0 1rem;
`
